import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from "@ionic/angular";

@Component({
    selector: 'app-modale-generica',
    templateUrl: './modale-generica.component.html',
    styleUrls: ['./modale-generica.component.scss'],
})
export class ModaleGenericaComponent implements OnInit {
    @Input() title: string;
    @Input() body: string;
    @Input() buttonDisabled: ['cancelButton', 'okButton'];

    constructor(private modalController: ModalController) {
    }

    ngOnInit() {
    //    
    }

    dismiss() {
        this.modalController.dismiss();
    }

    okButton() {
      this.modalController.dismiss({'buttonOk': true});    }
}
