import { DialCode } from 'src/app/core/enums/dial-code.enum';
import { IsoCode } from 'src/app/core/enums/iso-code.enum';

export class PhoneNumber {
    dialCode: DialCode;
    isoCode: IsoCode;
    nationalNumber: string;
    internationalNumber: string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
