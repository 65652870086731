import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {MatSelectModule} from '@angular/material/select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { environment } from 'src/environments/environment';
import { CustomDateAdapter } from './core/utils/custom-date-adapter';
import { IonIntlTelInputModule } from 'ion-intl-tel-input';
import { CustomInterceptorService } from './core/services/custom-interceptor.service';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { DatePipe, registerLocaleData } from '@angular/common';
import {MatIconModule} from "@angular/material/icon";
// import localeIt from '@angular/common/locales/it';
// registerLocaleData(environment.DEFAULT_LANGUAGE);
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        MatSelectModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        IonIntlTelInputModule,
        MatSnackBarModule,
        TranslateModule.forRoot({
            extend: true,
            defaultLanguage: environment.DEFAULT_LANGUAGE,
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        HttpClientModule,
        MatIconModule,
    ],
  providers: [
    DatePipe,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {provide: MAT_DATE_LOCALE, useValue: environment.DEFAULT_LANGUAGE},
    {provide: DateAdapter, useClass: CustomDateAdapter},
    {provide: LOCALE_ID, useValue: environment.DEFAULT_LANGUAGE},
    { 
      provide: HTTP_INTERCEPTORS, 
      useClass: CustomInterceptorService, 
      multi: true 
  }
  ],
  bootstrap: [AppComponent],
  exports:[TranslateModule]
})
export class AppModule {}
