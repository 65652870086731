import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export enum ErrorCode {
    "E000403" = "E000403",
    "E000500" = "E000500",
    "E000404" = "E000404",
    "E000400" = "E000400",
    "E001500" = "E001500",
    "E300403" = "E300403",
    "E301403" = "E301403",
    "E100400" = "E100400",
    "E101400" = "E100400",
    "E200400" = "E200400",
    "E100403" = "E100403",
    "E100404" = "E100404",
    "E104404" = "E104404",
    "E400403" = "E400403",
    "E500404" = "E500404",
    "E600404" = "E600404",
    "E700100" = "E700100",
    "E800100" = "E800100",
    "E800404" = "E800404",
    "E101404" = "E101404",
    "E400404" = "E400404",
    "E401404" = "E401404",
    "E400400" = "E400400",
    "E401400" = "E401400",
    "E900400" = "E900400",
    "E901400" = "E901400",
    "E700400" = "E700400",
    "E701400" = "E701400",
    "E700404" = "E700404",
    "E900404" = "E900404",
    "E901404" = "E901404",
    "E902404" = "E902404",
    "E700403" = "E700403",
    "E402404" = "E402404",
    "E401403" = "E401403",
    "E403404" = "E403404",
    "E402400" = "E402400",
    "E000401" = "E000401",
    "E1000403" = "E1000403",
    "E1001403" = "E1001403",
    "E1000404" = "E1000404",
    "PW_NO_MATCH" = "PW_NO_MATCH",
    "WARNING" = "WARNING",
    "ERROR" = "ERROR",
    "E402403" = "E402403",
}
// metodo per aggiungere gli enum al json di traduzione
marker('enum.ErrorCode.E000403');
marker('enum.ErrorCode.E000500');
marker('enum.ErrorCode.E000404');
marker('enum.ErrorCode.E000400');
marker('enum.ErrorCode.E001500');
marker('enum.ErrorCode.E300403');
marker('enum.ErrorCode.E301403');
marker('enum.ErrorCode.E100400');
marker('enum.ErrorCode.E200400');
marker('enum.ErrorCode.E100403');
marker('enum.ErrorCode.E100404');
marker('enum.ErrorCode.E400403');
marker('enum.ErrorCode.E500404');
marker('enum.ErrorCode.E600404');
marker('enum.ErrorCode.E700100');
marker('enum.ErrorCode.E800100');
marker('enum.ErrorCode.E800404');
marker('enum.ErrorCode.E101404');
marker('enum.ErrorCode.E400404');
marker('enum.ErrorCode.E401404');
marker('enum.ErrorCode.E400400');
marker('enum.ErrorCode.E401400');
marker('enum.ErrorCode.E900400');
marker('enum.ErrorCode.E901400');
marker('enum.ErrorCode.E700400');
marker('enum.ErrorCode.E701400');
marker('enum.ErrorCode.E700404');
marker('enum.ErrorCode.E101400');
marker('enum.ErrorCode.E900404');
marker('enum.ErrorCode.E901404');
marker('enum.ErrorCode.E902404');
marker('enum.ErrorCode.E700403');
marker('enum.ErrorCode.E402404');
marker('enum.ErrorCode.E401403');
marker('enum.ErrorCode.E403404');
marker('enum.ErrorCode.E402400');
marker('enum.ErrorCode.E000401');
marker('enum.ErrorCode.E1000403');
marker('enum.ErrorCode.E1001403');
marker('enum.ErrorCode.E1000404');
marker('enum.ErrorCode.PW_NO_MATCH');
marker('enum.ErrorCode.WARNING');
marker('enum.ErrorCode.ERROR');
marker('enum.ErrorCode.E402403');
