import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {ActivatedRoute} from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class ApiEndPointService {

    country = null;
    private endpointUriArray = environment.endpointUriArray;
    private endpointUri;


    constructor(private route: ActivatedRoute) {
        this.paramFromUrl();
    }

    private paramFromUrl() {
        this.route.queryParams.subscribe(params => {
            if (params.country) {
                this.country = params.country;
                this.endpointUri = this.filterEndpointUri(this.country)[0];
            }
        });
    }

    private filterEndpointUri(country: string) {
        if (country) {
            return this.endpointUriArray.filter(uri => uri.country == country)
        }
    }

    getEndpointUri() {
        return this.endpointUri?.uri;
    }
}
