import { Injectable } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PhoneNumber } from 'src/app/features/models/phone-number';
import { environment } from 'src/environments/environment';
import { DialCode } from '../enums/dial-code.enum';
import { IsoCode } from '../enums/iso-code.enum';
import { LanguageGetList } from '../models/language';
import { HateoasService } from './hateoas.service';
import {ApiEndPointService} from "./api-end-point.service";

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  public defaultLanguage: string = environment.DEFAULT_LANGUAGE;
  private languageApp: BehaviorSubject<{ language: string, phone: PhoneNumber }> = new BehaviorSubject({language: this.defaultLanguage, phone: new PhoneNumber({dialCode: DialCode[this.defaultLanguage], isoCode: IsoCode[this.defaultLanguage], nationalNumber: "", internationalNumber: DialCode[this.defaultLanguage]})});
  private enabledLanguage = environment.ACTIVE_LANGUAGE;
  private languageListBe: LanguageGetList[];
private endpointUri = this.apiEndPointService.getEndpointUri();

  constructor(
      private apiEndPointService: ApiEndPointService,
      private translateService: TranslateService,
      private dateAdapter: DateAdapter<any>,
      private hateosService: HateoasService
      ) {
      this.initLanguageApp();
  }

  private initLanguageApp() {
      this.translateService.addLangs(this.enabledLanguage);
      this.translateService.setDefaultLang(this.defaultLanguage);
      const storageLanguage: string = localStorage.getItem('appLanguage');
      const browserLanguage: string = this.translateService.getBrowserCultureLang();

      let appLanguage;
      if (storageLanguage && Object.values(this.enabledLanguage).some(arrayLang => arrayLang === storageLanguage)) {
          appLanguage = storageLanguage;
      } else {
          if (browserLanguage && Object.values(this.enabledLanguage).some(arrayLang => arrayLang === browserLanguage)) {
              appLanguage = browserLanguage;
          } else {
              appLanguage = this.defaultLanguage;
          }
      }
      this.setAppLanguage(appLanguage);
  }

  public setAppLanguage(lang: string) {
      localStorage.removeItem('appLanguage');
      this.languageApp.next({language: lang, phone: new PhoneNumber({dialCode: DialCode[lang], isoCode: IsoCode[lang], nationalNumber: "", internationalNumber: DialCode[lang]})});
      localStorage.setItem('appLanguage', lang);
      this.translateService.use(lang);
      this.dateAdapter.setLocale(lang);
  }

  public getAllLanguage() {
      return this.enabledLanguage;
  }

  public getAppLanguage(): BehaviorSubject<{ language: string, phone: PhoneNumber }> {
      return this.languageApp;
  }
  
  public getLangaugesFromBe(): Observable<LanguageGetList> {
      const url = `${this.endpointUri}/languages`;
      return this.hateosService.get<LanguageGetList>(url);
  }
}
