import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {BehaviorSubject, Subscription} from 'rxjs';
import {LanguageEnum} from './core/enums/language.enum';
import {LanguageService} from './core/services/language.service';
import {UtilityService} from './core/services/utility.service';
import {ModaleGenericaComponent} from "./shared/components/modale-generica/modale-generica.component";
import {ModalController} from "@ionic/angular";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {

    languages: LanguageEnum[] = this.languageService.getAllLanguage()
    langueControl: FormControl = new FormControl();
    langSub: Subscription = new Subscription();
    loaderSubscription: Subscription = new Subscription();
    enabledLoadergBar: boolean = false;
    // logo: string = './assets/images/logo-avis-white.png';
    // logo: string = './assets/images/logo-budget-white.png';
    logo: BehaviorSubject<string> = this.utilityService.getBrandApp();

    constructor(
        public languageService: LanguageService,
        private utilityService: UtilityService,
        private translateService: TranslateService,
        private modalCtrl: ModalController,

    ) {
    }

    ngOnInit() {
        this.languageService.getAppLanguage().subscribe(value => {
            this.langueControl.setValue(value.language);
        }).unsubscribe();
        this.langSub = this.langueControl.valueChanges.subscribe(language => {
            this.languageService.setAppLanguage(language);
        });

        this.loaderSubscription = this.utilityService.getStatusLoaderBar().subscribe(loader => {
            setTimeout(() => {
                this.enabledLoadergBar = loader.isEnabled;
            }, 0);
        });
    }

    ngOnDestroy() {
        this.langSub.unsubscribe();
    }

    async openModalinfo() {
        let title = 'Info';
        let bodyText = `${this.translateService.instant('InfoModal.reservationNumberInfo')} <br>`;
        bodyText += `${this.translateService.instant('InfoModal.nameInfo')} <br>`;
        bodyText += `${this.translateService.instant('InfoModal.surnameInfo')} <br>`;
        bodyText += `${this.translateService.instant('InfoModal.phoneNumberInfo')} <br>`;
        bodyText += `${this.translateService.instant('InfoModal.emailInfo')} <br>`;


        const modal = await this.modalCtrl.create({
            component: ModaleGenericaComponent,
            cssClass: 'pending-modal',
            backdropDismiss: false,

            componentProps: {
                title: title,
                body: bodyText,
                buttonDisabled: ['okButton']
            }
        });

        modal.onDidDismiss().then(result => {
            if (result.data) {
                console.log('result', result)
            }
        });
        return await modal.present();
    }
}
