import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import {inject, Injectable, Injector} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ErrorCode } from '../enums/error-code.enum';
import { ShUserNotifyService } from './sh-user-notify.service';
import { UtilityService } from './utility.service';

@Injectable({
  providedIn: 'root'
})
export class CustomInterceptorService implements HttpInterceptor {

  translateService;

  constructor(private utilityService: UtilityService, private shUserNotifyService: ShUserNotifyService,  private injector: Injector) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // this.translateService = this.injector.get(TranslateService);
    this.utilityService.startLoaderBar();

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.utilityService.stopLoaderBar();
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        this.utilityService.stopLoaderBar();
        if (error.error && error.error.code && ErrorCode[error.error.code]) {
          // this.shUserNotifyService.notifyError(this.translateService.instant(error.error.code));
        }
        return throwError(error);
      })
      );
  }
}
