import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {BehaviorSubject} from 'rxjs';
import {CustomSnackbarComponent} from 'src/app/shared/components/custom-snackbar/custom-snackbar.component';
import {BrandsEnum} from "../enums/brands.enum";
import {HateoasService} from "./hateoas.service";
import {ApiEndPointService} from "./api-end-point.service";
import {HttpClient} from "@angular/common/http";
import {switchAll, switchMap, take} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class UtilityService {

    static snackBar: MatSnackBar;
    private loaderBar = new BehaviorSubject({isEnabled: false, counter: 0});
    private brandApp = new BehaviorSubject('./assets/images/logo-avis-white.png');

    constructor(
        private apiEndPointService: ApiEndPointService,
        private hateaosService: HateoasService,
        public http: HttpClient
    ) {
    }

    public static getIdBySelfHref(selfHref) {
        let index = selfHref.lastIndexOf("/");
        let id = '-1';
        if (index != -1) id = selfHref.substring(index + 1, selfHref.length);
        return Number(id);
    }

    static showMessage(message, type?) {
        type = type != null ? type : 'Info';
        this.snackBar.openFromComponent(CustomSnackbarComponent, {
            duration: 4000,
            panelClass: type,
            data: {message: message, snackType: type}
        });

    }

    startLoaderBar() {
        this.loaderBar.next({
            isEnabled: true,
            counter: this.loaderBar.value.counter + 1
        });
    }

    stopLoaderBar(reset = false) {
        this.loaderBar.next({
            isEnabled: (this.loaderBar.value.counter <= 1 || reset === false) ? false : true,
            counter: (this.loaderBar.value.counter <= 1 || reset === false) ? this.loaderBar.value.counter - 1 : 0
        });
    }

    getStatusLoaderBar(): BehaviorSubject<any> {
        return this.loaderBar;
    }

    setBrandApp(idCompany: string) {
        let endpointUri = this.apiEndPointService.getEndpointUri();
        let brand;
        if (idCompany) {
            const URL = `${endpointUri}/bookAppointment/getCompanyName?id=${idCompany}`;
            this.http.get(URL, {responseType: "text"})
                .pipe(

                    switchMap(brand => {
                        if (brand && (<any>Object).values(BrandsEnum).includes(brand.toLowerCase())) {
                            let logoUrl = `./assets/images/logo-${brand.toLowerCase()}-white.png`;
                            this.brandApp.next(logoUrl);
                            return brand
                        }
                    }),
                    take(5),
                )
                .subscribe(res => {
                        brand = res;
                    },
                );
        }

    }

    getBrandApp(): BehaviorSubject<string> {
        return this.brandApp;
    }
}
