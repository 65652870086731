// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { LanguageEnum } from 'src/app/core/enums/language.enum';

export const environment = {
  production: false,
  // endpointUri: "",
  //  utilizza il servizio api-end-point metodo getEndpointUri per la uri delle api
  endpointUriArray: [
      {country: 'it', uri: 'https://smart-checkout-test.dstech.info/api'},
      {country: 'es', uri: 'https://smart-checkout-test-es.dstech.info/api'},
      {country: 'de', uri: 'https://smart-checkout-uat-de.dstech.info/api'},
      {country: 'uk', uri: 'https://smart-checkout-uat-uk.dstech.info/api'},
      {country: 'fr', uri: 'https://smart-checkout-uat-fr.dstech.info/api'},
  ],
  DEFAULT_LANGUAGE: LanguageEnum.ENGLISH,
  ACTIVE_LANGUAGE: [LanguageEnum.ENGLISH, LanguageEnum.ITALIANO, LanguageEnum.ESPANOL, LanguageEnum.GERMAN, LanguageEnum.PORTUGUESE],
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
